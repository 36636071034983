<template>
    <div>
        <v-divider class="mx-0" />
        <v-list-item @click="openResource">
            <v-list-item-content class="mr-3">
                <v-row>
                    <v-col cols="12" class="px-3">
                        <v-list-item-title>
                            <div class="d-flex items-center">
                                <v-tooltip right v-if="notification.tooltip">
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon>
                                            <v-icon
                                                :color="
                                                    notification.read
                                                        ? 'primary'
                                                        : ''
                                                "
                                                class="d-flex justify-left ml-n3"
                                            >
                                                {{
                                                    'mdi-information-variant-box'
                                                }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span
                                        class="my-0 text-left"
                                        v-for="(value,
                                        key) in notification.tooltip"
                                        :key="key"
                                    >
                                        {{ key }}: {{ value }}<br />
                                    </span> </v-tooltip
                                ><span class="d-flex align-center text-wrap">{{
                                    notification.message
                                }}</span>
                            </div>
                        </v-list-item-title>
                        <div style="font-size: 13px" class="mt-1">
                            {{ formatDate(notification.createdOn.seconds) }}
                        </div>
                    </v-col>
                </v-row>
            </v-list-item-content>

            <v-list-item-action class="ml-2">
                <v-row>
                    <v-btn
                        v-if="
                            !persistentNotifications.includes(
                                notification.reference
                            )
                        "
                        icon
                        @click.stop="changeReadStatus(notification)"
                        :loading="readLoading"
                    >
                        <v-icon>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </v-row>
            </v-list-item-action>
        </v-list-item>
    </div>
</template>

<script>
import API from '@/services/api'
import moment from 'moment'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'NotificationsBell',
    props: {
        notification: {
            type: Object,
            default: () => ({}),
        },
        notificationDocs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            readLoading: false,
            deleteLoading: false,
            alertNotifications: [
                'pFDh25EcR74EMiuypq8M', // alert
            ],
            persistentNotifications: [
                '7DihWLWnvZMvh5aHNeEv', // create a lead
                'nVHma9txWN3DeJU3Ax7t', // approve a lead
                'x4KP5EMG94gwjayCVSfv', // new quote assigned to estimator
                'v54UZX76qfydKPyDdhWb', // quote to be requoted - estimator
                'hj42GV1OQ61dojcYyoIn', // quote for approval
                '2TvwiTwXNif7OrJ3CmOv', // scope assigned
                'T4wnlVuEHOUPwMPQkAWl', // scope rejected
                'IRwvcitCKm39nSYhhQqV', // scope engineer assigned
                'AkAPWh7qBWxCfCL12WqN', // create an international invoice
                'WNzjPtOPmiwPEuv0vRc5', // create an local invoice
                '2o3QIGSpJM5Zb1Fpd8jW', // invoice
                'z4yw1pMEbJAt5r0oz4FZ', // invoice
                '1uT4WecbJ86BTcO7UFIW', // invoice
                'bgOJPzzceetopCQj5kxs', // invoice
                'oEZbNBsm7NuoohIWRRKo', // invoice
            ],
        }
    },
    computed: {
        ...mapState(['settings']),
    },
    methods: {
        ...mapActions(['setNotificationResource']),
        async changeReadStatus() {
            try {
                this.readLoading = true
                // remove a notification
                await API.checkAlertNotification({
                    resourceId: this.notification.resourceId,
                    notificationId: this.notification.reference,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.readLoading = false
            }
        },

        async deleteNotification() {
            try {
                this.deleteLoading = true
                // remove a notification
                await API.checkAlertNotification({
                    resourceId: this.notification.resourceId,
                    notificationId: this.notification.reference,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.deleteLoading = false
            }
        },

        formatDate(seconds) {
            return moment.unix(seconds).format('DD-MM-YY h:mm a')
        },

        async openResource() {
            const path = this.notification.path
            const currentRoute = this.$route.path
            const resourceId = this.notification.resourceId
            if (path == '/chat-quote') {
                const {
                    data: { quotes },
                } = await API.getQuote(resourceId)
                this.$emit('openQuote', quotes)
            } else if (currentRoute !== path) {
                this.$router.push({
                    path: `${path}?resourceId=${resourceId}`,
                })
            } else {
                this.setNotificationResource(resourceId)
            }

            if (this.alertNotifications.includes(this.notification.reference)) {
                // remove a notification
                await API.checkAlertNotification({
                    resourceId: this.notification.resourceId,
                    notificationId: this.notification.reference,
                })
            }
        },
    },
}
</script>
