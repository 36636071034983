var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-divider',{staticClass:"mx-0"}),_c('v-list-item',{on:{"click":_vm.openResource}},[_c('v-list-item-content',{staticClass:"mr-3"},[_c('v-row',[_c('v-col',{staticClass:"px-3",attrs:{"cols":"12"}},[_c('v-list-item-title',[_c('div',{staticClass:"d-flex items-center"},[(_vm.notification.tooltip)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{staticClass:"d-flex justify-left ml-n3",attrs:{"color":_vm.notification.read
                                                    ? 'primary'
                                                    : ''}},[_vm._v(" "+_vm._s('mdi-information-variant-box')+" ")])],1)]}}],null,false,2836541178)},_vm._l((_vm.notification.tooltip),function(value,key){return _c('span',{key:key,staticClass:"my-0 text-left"},[_vm._v(" "+_vm._s(key)+": "+_vm._s(value)),_c('br')])}),0):_vm._e(),_c('span',{staticClass:"d-flex align-center text-wrap"},[_vm._v(_vm._s(_vm.notification.message))])],1)]),_c('div',{staticClass:"mt-1",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.notification.createdOn.seconds))+" ")])],1)],1)],1),_c('v-list-item-action',{staticClass:"ml-2"},[_c('v-row',[(
                        !_vm.persistentNotifications.includes(
                            _vm.notification.reference
                        )
                    )?_c('v-btn',{attrs:{"icon":"","loading":_vm.readLoading},on:{"click":function($event){$event.stopPropagation();return _vm.changeReadStatus(_vm.notification)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }